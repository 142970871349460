import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import styled from '@emotion/styled';
import { formatCurrency } from 'utils/rifmFormatters';
import DropdownMenu from 'components/DropdownMenu';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Input from 'components/Input';
import Slider from 'components/Slider';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import listingTypes from 'utils/listingTypes.json';
import InputCurrency from './InputCurrency';

const PRICE_MAX = 10000000;
const SLIDER_MULTIPLIER = PRICE_MAX / 100;

const currencyToNumber = (str = '') => {
  const value = str.replace('$', '').replaceAll(',', '');
  return value;
};

const listingTypeOptions: DropdownOption[] = Object.values(listingTypes).map(value => ({
  value,
  text: value
}));

interface FiltersProps extends React.HTMLAttributes<HTMLFormElement> {
  onSubmit: (values) => void;
}

const Filters: React.FC<FiltersProps> = ({ onSubmit: onSubmitProp, ...props }) => {
  const { register, control, watch, handleSubmit, setValue, formState } = useFormContext();
  const [priceMin, priceMax, zip, listingTypes] = watch([
    'priceMin',
    'priceMax',
    'zip',
    'listingTypes'
  ]);
  const priceMinNumber = currencyToNumber(priceMin);
  const priceMaxNumber = currencyToNumber(priceMax);

  const priceSliderValue = [priceMinNumber, priceMaxNumber].map(
    item => Number(item) / SLIDER_MULTIPLIER || 0
  );

  let priceText = '';
  if (priceMinNumber && !priceMaxNumber) priceText = `${formatCurrency(priceMin)}+`;
  else if (priceMaxNumber) priceText = `${formatCurrency(priceMin)}-${formatCurrency(priceMax)}`;
  let zipText = zip;
  const splitZip = zip
    ? zip
        .split(',')
        .map(item => item.trim())
        .filter(Boolean)
    : [];
  if (splitZip.length > 3) {
    zipText = `${splitZip.length} postal codes`;
  }

  let listingTypeText = '';
  if (listingTypes) {
    if (listingTypes.length === listingTypeOptions.length) listingTypeText = 'All';
    else if (listingTypes.length > 1) listingTypeText = `${listingTypes.length} types`;
    // eslint-disable-next-line prefer-destructuring
    else listingTypeText = listingTypes[0];
  }

  const handleSliderChange = sliderValue => {
    const [priceMin, priceMax] = sliderValue.map(item =>
      formatCurrency(String(item * SLIDER_MULTIPLIER))
    );
    setValue('priceMin', priceMin, { shouldDirty: true });
    setValue('priceMax', priceMax, { shouldDirty: true });
  };

  const clearPrices = () => {
    setValue('priceMin', '');
    setValue('priceMax', '');
  };

  const submit = values => {
    onSubmitProp({ ...values, priceMin: priceMinNumber, priceMax: priceMaxNumber });
  };

  return (
    <StyledFilters onSubmit={handleSubmit(submit)} {...props}>
      <DropdownMenu
        className="price"
        label="Price:"
        value={priceText}
        placeholder="All"
        menuPlacement="bottom-start">
        <div className="inputs">
          <Controller
            control={control}
            name="priceMin"
            defaultValue=""
            render={({ field }) => <InputCurrency label="Min" min={0} max={PRICE_MAX} {...field} />}
          />
          <Controller
            control={control}
            name="priceMax"
            defaultValue=""
            render={({ field }) => (
              <InputCurrency label="Max" min={priceMin} max={PRICE_MAX} {...field} />
            )}
          />
        </div>
        <Slider
          value={priceSliderValue}
          defaultValue={priceSliderValue}
          onChange={handleSliderChange}
        />
        <div className="clear-btn-container">
          <Button simple type="button" onClick={clearPrices}>
            × Clear
          </Button>
        </div>
      </DropdownMenu>
      <DropdownMenu
        className="postal-code"
        menuPlacement="bottom-start"
        value={zipText}
        label="ZIP:"
        placeholder="All">
        <div className="hint">Enter postal codes separated by commas</div>
        <Input {...register('zip')} placeholder="Postal Codes" />
      </DropdownMenu>
      <Controller
        control={control}
        name="listingTypes"
        defaultValue={[]}
        render={({ field }) => (
          <Dropdown
            buttonLike
            multiple
            options={listingTypeOptions}
            text={listingTypeText}
            optionAll
            optionAllLabel="All types"
            label="Listing Type:"
            placeholder="All"
            {...field}
          />
        )}
      />
      <Checkbox {...register('onlyWithPhotos')} label="Only with photos" />
      <Button className="apply" disabled={!formState.isDirty}>
        Apply Filters
      </Button>
    </StyledFilters>
  );
};

export default Filters;

const StyledFilters = styled.form`
  display: flex;

  .dropdown,
  .dropdown-menu {
    margin-right: 12px;
  }

  .checkbox {
    margin: 0 28px 0 6px;
  }

  .dropdown-menu,
  .dropdown {
    min-width: 120px;
    max-width: 240px;

    &.price .dropdown-menu-content {
      width: 272px;

      .inputs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .input {
          width: 100%;
          min-width: 108px;

          &:first-of-type {
            margin-right: 32px;
          }
        }
      }
    }

    .clear-btn-container {
      text-align: center;
      margin-top: 20px;

      .button {
        color: ${props => props.theme.colors.red};
      }
    }

    &.postal-code .dropdown-menu-content {
      width: 260px;

      .hint {
        font-size: 10px;
        color: ${props => props.theme.colors.grayDark};
        margin-bottom: 10px;
      }

      .input {
        width: 100%;
      }
    }
  }

  .apply {
    white-space: nowrap;
  }
`;
